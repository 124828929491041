<template>
    <div>
        <b-row>
            <b-col>
                <b-card>
                    <h4>Información de la entidad</h4>
                    <b-row>
                        <b-col class="text-right">
                            <b-button
                                variant="flat-success"
                                pill
                                class="px-75 mr-1"
                            >
                                <feather-icon
                                    icon="EditIcon"
                                    size="20"
                                ></feather-icon>
                            </b-button>
                            <b-button variant="flat-danger" pill class="px-75">
                                <feather-icon
                                    icon="Trash2Icon"
                                    size="20"
                                ></feather-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                    <b-row class="pb-1">
                        <b-col class="text-center">
                            <b-avatar
                                variant="success"
                                size="80"
                                rounded
                                class="mb-1"
                            >
                                <feather-icon
                                    icon="HomeIcon"
                                    size="50"
                                ></feather-icon>
                            </b-avatar>

                            <h4>Nueva Eps</h4>
                            <div class="mb-1">
                                <b-badge variant="success">Activa</b-badge>
                                <!-- <b-badge variant="danger">Inactiva</b-badge> -->
                                <!-- <b-badge variant="info">En liquidación</b-badge> -->
                            </div>
                            <p class="text-muted m-0">Creado el 20 ago. 2022</p>
                        </b-col>
                    </b-row>
                    <b-row class="pb-1 border-bottom">
                        <div class="d-inline px-1 text-justify">
                            <p class="my-0">
                                El propósito de NUEVA EPS es la protección
                                integral de la salud de nuestros afiliados,
                                razón por la cual nos enfocamos en gestionar sus
                                riesgos, con un alto compromiso en prevenir,
                                mantener o mejorar sus condiciones de bienestar
                                y la de su grupo familiar.
                            </p>
                        </div>
                    </b-row>
                    <b-row>
                        <b-col>
                            <div
                                class="d-flex justify-content-around align-items-center h-100 my-1"
                            >
                                <div
                                    v-for="(
                                        indicador, index
                                    ) in indicadoresEntidad"
                                    :key="index"
                                    class="text-center"
                                >
                                    <b-avatar
                                        :variant="indicador.variant.color"
                                        class="p-25 mb-25"
                                        size="lg"
                                    >
                                        <feather-icon
                                            :icon="indicador.variant.icono"
                                            size="40"
                                        ></feather-icon>
                                    </b-avatar>
                                    <p
                                        class="font-weight-bolder font-medium-4 mb-25"
                                    >
                                        {{ indicador.numero }}
                                    </p>
                                    <p>{{ indicador.nombre }}</p>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col>
                <b-tabs>
                    <b-tab title="Asistencias">
                        <asistencias />
                    </b-tab>
                    <b-tab title="Profesionales" lazy>
                        <profesionales />
                    </b-tab>
                    <b-tab title="Pacientes" lazy>
                        <pacientes />
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { ref } from "@vue/composition-api";
export default {
    components: {
        Asistencias: () => import("./tabs/Asistencias.vue"),
        Profesionales: () => import("./tabs/Profesionales.vue"),
        Pacientes: () => import("./tabs/Pacientes.vue"),
    },
    setup(props, context) {
        const indicadoresEntidad = ref([
            {
                nombre: "Pacientes",
                numero: 122,
                variant: {
                    icono: "UsersIcon",
                    color: "light-success",
                },
            },
            {
                nombre: "Profesionales",
                numero: 555,
                variant: {
                    icono: "UsersIcon",
                    color: "light-info",
                },
            },
            {
                nombre: "Dispositivos",
                numero: 122,
                variant: {
                    icono: "HardDriveIcon",
                    color: "light-secondary",
                },
            },
        ]);

        return { indicadoresEntidad };
    },
};
</script>
